import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { modalKeys } from 'config/modals'
import { useModal } from 'hooks/application'
import Button from 'components/common/Button'
import styles from './CollectionActions.module.scss'
import SkipModal from './SkipModal'
import SwapModal from './SwapModal'
import UnassignModal from './UnassignModal'

type Props = {
  collectionId: number
  isOngoing?: boolean
}

const CollectionActions = ({ collectionId, isOngoing }: Props) => {
  const { t } = useTranslation()
  const { openModal: openUnassignModal } = useModal(modalKeys.UNASSIGN_COLLECTION)
  const { openModal: openSkipModal } = useModal(modalKeys.SKIP_COLLECTION)
  const { openModal: openSwapModal } = useModal(modalKeys.SWAP_COLLECTION)

  return (
    <>
      <div className={styles.collectionActions}>
        {isOngoing === true && (
          <>
            <Button
              secondary
              onClick={() => openSkipModal()}
              testId="skip-button"
            >
              {t('your_collections.show.buttons.skip')}
            </Button>

            <Button
              secondary
              onClick={() => openSwapModal()}
            >
              {t('your_collections.show.buttons.swap')}
            </Button>
          </>
        )}
        {isOngoing === false ? (
          <Button
            className={styles.adhocButton}
            secondary
            onClick={() => openUnassignModal()}
          >
            {t('your_collections.show.buttons.unassign_adhoc')}
          </Button>
        ) : (
          <Button
            secondary
            onClick={() => openUnassignModal()}
          >
            {t('your_collections.show.buttons.unassign')}
          </Button>
        )}
      </div>
      <UnassignModal collectionId={collectionId} />
      <SkipModal collectionId={collectionId} />
      <SwapModal collectionId={collectionId} />
    </>
  )
}

export default memo(CollectionActions)
