import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@redux/hooks'
import { useCollection } from 'hooks/collections'
import Note from 'components/Collections/Note'
import Rota from 'components/common/StoreRota/Rota'

type Props = {
  collectionId?: number
}

const CollectionDetails = ({ collectionId }: Props) => {
  const { t } = useTranslation()

  const { data, windowInfo } = useCollection({ collectionId })
  const { business, storeId, store } = data || {}
  const { collectionNotes } = windowInfo
  const selectedStore = useAppSelector((state) => state.stores?.items[storeId])
  const storeNotes = store?.notes?.trim()

  const businessNotes = business?.notes?.trim()

  const hasNotes = Boolean(collectionNotes || storeNotes || businessNotes)

  return (
    <div className="collection-details">
      <div className={hasNotes === true ? 'notes-container' : 'notes-container no-border-mobile'}>
        {collectionNotes !== undefined && collectionNotes !== '' && collectionNotes !== null && (
          <Note
            title={t('your-collections.page.show.details.notes.collection-notes')}
            note={collectionNotes}
            className="note-wrapper collection-wrapper"
          />
        )}
        {storeNotes !== undefined && storeNotes !== '' && storeNotes !== null && (
          <Note
            title={t('your-collections.page.show.details.notes.store-notes')}
            note={storeNotes}
            className="note-wrapper"
          />
        )}
        {businessNotes !== undefined && businessNotes !== '' && businessNotes !== null && (
          <Note
            title={t('your-collections.page.show.details.notes.business-notes')}
            note={businessNotes}
            className="note-wrapper business-notes"
          />
        )}
      </div>

      <Rota
        storeId={storeId}
        className="rota-wrapper"
        timezone={selectedStore?.timezone}
      />
    </div>
  )
}

export default CollectionDetails
