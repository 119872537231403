import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@redux/hooks'
import fetchCollection from '@redux/thunks/collections/fetchCollection'
import { modalKeys } from 'config/modals'
import { MY_COLLECTIONS_URL } from 'config/routes'
import { useModal } from 'hooks/application'
import { useCollection } from 'hooks/collections'
import CollectionConfirmationInfo from 'components/Collections/CollectionConfirmationInfo'
import Button from 'components/common/Button'
import SuccessModal from 'components/common/Modal/SuccessModal'
import { Spacer } from 'components/layout/primitives'
import styles from './ClaimCollectionSuccessModal.module.scss'

const ClaimCollectionSuccessModal = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    closeModal,
    data: { collectionId },
  } = useModal(modalKeys.CLAIM_COLLECTION_SUCCESS)

  const navigate = useNavigate()
  const { data: collection } = useCollection({ collectionId })

  const navigateToMyCollectionDetails = () => {
    const myCollectionDetailsUrl = `${MY_COLLECTIONS_URL}/${collectionId}?new=true`
    navigate(myCollectionDetailsUrl)
  }

  const handleCloseModal = () => {
    closeModal()
    navigateToMyCollectionDetails()
  }

  useEffect(() => {
    if (collectionId !== undefined && collection?.nextCollection === undefined) {
      dispatch(fetchCollection(collectionId))
    }
  }, [collectionId, collection.nextCollection])

  return (
    <SuccessModal
      onClose={handleCloseModal}
      modalKey={modalKeys.CLAIM_COLLECTION_SUCCESS}
      className={styles.claimCollectionSuccessModal}
    >
      <h2>{t('collection-slots.claim.success_modal.success')}</h2>
      <h2>{t('collection-slots.claim.success_modal.title')}</h2>
      <Spacer h="3rem" />
      <CollectionConfirmationInfo
        businessName={collection?.business?.name}
        storeName={collection?.store?.name}
        storeAddress={collection?.store?.address}
        collectionTime={collection?.nextCollection}
        collectionType={collection?.type}
        timezone={collection?.store?.timezone}
      />
      <Spacer h="3rem" />
      <Button
        className={styles.viewCollectionDetailsButton}
        onClick={() => {
          closeModal()
          navigateToMyCollectionDetails()
        }}
      >
        {t('collection-slots.claim.success_modal.view_collection_details')}
      </Button>
    </SuccessModal>
  )
}

export default ClaimCollectionSuccessModal
