import { memo, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import closeSkipModal from '@redux/thunks/collections/closeSkipModal'
import closeSwapModal from '@redux/thunks/collections/closeSwapModal'
import closeUnassignModal from '@redux/thunks/collections/closeUnassignModal'
import fetchCollection from '@redux/thunks/collections/fetchCollection'
import fetchCollectionValidEndDates from '@redux/thunks/collections/fetchCollectionValidEndDates'
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections'
import setSelectedOptions from '@redux/thunks/collections/setSelectedOptions'
import fetchStore from '@redux/thunks/stores/fetchStore'
import { COLLECTION_TYPES } from 'config/collections'
import { modalKeys } from 'config/modals'
import {
  COLLECTION_ACTIONS,
  MODAL_MODES,
  getActionModalKey,
  getSwappableCollections,
} from 'helpers/collections'
import Debug from 'helpers/debug'
import { ConfirmationModal } from 'components/common/Modal/ConfirmationModal'
import Forbidden from './Forbidden'
import Options from './Options'
import Success from './Success'
import Warning from './Warning'

const { UNASSIGN_COLLECTION, SKIP_COLLECTION, SWAP_COLLECTION } = modalKeys

type Props = {
  collectionId: number
  mode: string
}

const ActionsModal = ({ collectionId, mode }: Props) => {
  const dispatch = useAppDispatch()
  const debug = Debug(`javascript:components:collection-${mode}-modal`)

  const modalMode = useAppSelector((state) => state.collections[mode].modalMode)
  const selectedCollectionId = useAppSelector(
    (state) => state.collections[mode].selectedCollectionId,
  )
  const collection = useAppSelector((state) => state.collections.items[selectedCollectionId])
  const selectedStoreId = collection?.storeId
  const storeCollectionIds = useAppSelector(
    (state) => state.stores.items[selectedStoreId]?.collections,
  )
  const collections = useAppSelector((state) => state.collections.items)

  const selectedStoreCollections = storeCollectionIds?.map((id) => collections[id])
  const modalKey = getActionModalKey(mode)

  const actionOnClose = () => {
    switch (modalKey) {
      case UNASSIGN_COLLECTION:
        dispatch(closeUnassignModal())
        break
      case SKIP_COLLECTION:
        dispatch(closeSkipModal())
        break
      case SWAP_COLLECTION:
        dispatch(closeSwapModal())
        break
      default:
        break
    }
  }

  useEffect(() => {
    try {
      if (selectedCollectionId === undefined) {
        dispatch(fetchCollection(collectionId)).catch(debug)
        dispatch(fetchCollectionValidEndDates(collectionId)).catch(debug)
        dispatch(
          setSelectedOptions({
            action: mode,
            collectionId,
          }),
        )
      } else {
        dispatch(fetchCollectionValidEndDates(selectedCollectionId)).catch(debug)
      }
    } catch (error) {
      debug(error)
    }
  }, [selectedCollectionId])

  useEffect(() => {
    if (selectedCollectionId) {
      try {
        dispatch(fetchCollection(selectedCollectionId)).catch(debug)
      } catch (error) {
        debug(error)
      }
    }
  }, [selectedCollectionId])

  useEffect(() => {
    if (selectedStoreId) {
      try {
        dispatch(
          setSelectedOptions({
            action: mode,
            storeId: selectedStoreId,
          }),
        )
        dispatch(fetchStore(selectedStoreId)).catch(debug)
      } catch (error) {
        debug(error)
      }
      dispatch(fetchStoreCollections({ storeId: selectedStoreId })).catch(debug)
    }
  }, [selectedStoreId])

  useEffect(() => {
    if (
      mode === COLLECTION_ACTIONS.SWAP &&
      modalMode === MODAL_MODES.FORBIDDEN &&
      collection?.type === COLLECTION_TYPES.ONGOING &&
      selectedStoreCollections !== undefined
    ) {
      const swappableCollections = getSwappableCollections(selectedStoreCollections)
      if (swappableCollections.length !== 0) {
        dispatch(
          setSelectedOptions({
            action: mode,
            modalMode: MODAL_MODES.WARNING,
          }),
        )
      }
    }
  }, [mode, selectedStoreCollections, modalMode])

  return (
    <>
      {modalMode !== MODAL_MODES.SUCCESS && (
        <ConfirmationModal
          id="collection-modal-wizard"
          modalKey={modalKey}
          actionOnClose={actionOnClose}
        >
          {modalMode === MODAL_MODES.OPTIONS && <Options mode={mode} />}
          {modalMode === MODAL_MODES.WARNING && <Warning mode={mode} />}
          {modalMode === MODAL_MODES.FORBIDDEN && <Forbidden />}
        </ConfirmationModal>
      )}

      {modalMode === MODAL_MODES.SUCCESS && <Success mode={mode} />}
    </>
  )
}

export default memo(ActionsModal)
