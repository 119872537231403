import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDone } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import setActiveStep from '@redux/thunks/collections/setActiveStep'
import {
  STEP_COLLECTION,
  STEP_DATE,
  STEP_TARGET_COLLECTION,
  getCollectionActionDetails,
} from 'helpers/collections'
import Debug from 'helpers/debug'

const debug = Debug(`javascript:components:collection-actions:options:step:step-header`)

type Props = {
  step?: string
  mode?: string
}

const StepHeader = ({ step, mode }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { selectedCollectionId, step: activeStep } = useAppSelector(
    (state) => state.collections[mode],
  )

  const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
    return getCollectionActionDetails(t)
  }, [])

  const collection = useAppSelector((state) => state.collections.items[selectedCollectionId])
  const selectedCollectionDate = collection?.nextCollection

  const activeStepPosition = COLLECTION_ACTIONS_DETAILS[mode].steps[activeStep].position

  const isActiveStep = activeStepPosition >= COLLECTION_ACTIONS_DETAILS[mode].steps[step].position

  const isStepClickable = () => {
    if (activeStep !== step) {
      switch (step) {
        case STEP_COLLECTION:
          return true
        case STEP_DATE:
          return selectedCollectionDate !== undefined
        case STEP_TARGET_COLLECTION:
          return true
        default:
          debug('Invalid collection action step provided')
          break
      }
    }
    return false
  }

  const setStep = () => {
    if (isStepClickable()) {
      debug(`Selecting step ${step} on ${mode} modal`)
      dispatch(setActiveStep({ action: mode, step }))
    }
  }

  return (
    <div className="description">
      <button
        type="button"
        className={`no-button-styling icon${isActiveStep ? ' active' : ''}`}
        onClick={setStep}
        aria-label={COLLECTION_ACTIONS_DETAILS[mode].steps[step].header}
      >
        {activeStepPosition <= COLLECTION_ACTIONS_DETAILS[mode].steps[step].position ? (
          COLLECTION_ACTIONS_DETAILS[mode].steps[step].position
        ) : (
          <MdDone data-testid="doneIcon" />
        )}
      </button>
      <strong>{COLLECTION_ACTIONS_DETAILS[mode].steps[step].header}</strong>
    </div>
  )
}

export default StepHeader
