import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { COLLECTION_ACTIONS } from 'helpers/collections'
import { formatLongDate, formatDateToDayAndTime24 } from 'helpers/time'
import styles from './CollectionConfirmationInfo.module.scss'

const { UNASSIGN, SKIP, SWAP } = COLLECTION_ACTIONS
const CollectionConfirmationInfo = ({
  businessName,
  storeName,
  storeAddress,
  collectionTime,
  collectionType = undefined,
  endDate = undefined,
  mode = undefined,
  timezone,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <h3 className={styles.businessAndStoreName}>
        {t('collection-slots.collection-confirmation-info.business-and-store', {
          businessName,
          storeName,
        })}
      </h3>
      <p className={styles.address}>
        {storeAddress &&
          Object.values(storeAddress).map((addressLine: string, index) => {
            return <span key={addressLine + index}>{addressLine}</span>
          })}
      </p>
      {mode === UNASSIGN &&
        (DateTime.fromISO(endDate) <= DateTime.now() || endDate === null ? (
          <p>{t('collection-slots.collection-confirmation-info.immediately')}</p>
        ) : (
          <p>
            {t('collection-slots.collection-confirmation-info.last-collection', {
              endDate: formatLongDate(
                DateTime.fromISO(collectionTime) // endDate is not set to the correct time, but 23:59
                  .set({
                    day: DateTime.fromISO(endDate).toUTC().day,
                    month: DateTime.fromISO(endDate).toUTC().month,
                  })
                  .toISO(),
                timezone,
              ),
            })}
          </p>
        ))}
      {mode === SKIP &&
        Array.isArray(endDate) &&
        endDate.map((date, index) => <p key={index}>{formatLongDate(date, timezone)}</p>)}
      {mode !== UNASSIGN && mode !== SKIP && (
        <>
          <p className={styles.on}>{t('collection-slots.collection-confirmation-info.on')}</p>
          <p className={styles.collectionTime}>
            {mode === SWAP
              ? formatDateToDayAndTime24(collectionTime, timezone)
              : formatLongDate(collectionTime, timezone)}
          </p>
          {collectionType && (
            <p className={styles.collectionType}>
              {t('collection-slots.collection-confirmation-info.collection-type', {
                collectionType: t(`collection-slots.claim.types.${collectionType}`),
              })}
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default CollectionConfirmationInfo
