import {
  Route,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  LandingPageRedirects,
  RequireAuthentication,
  RequireFoodHeroRegistrationAccess,
  RequireNoAuthentication,
  RequireNonFoodHeroAccess,
} from 'services/User/Access'
import { LOGIN_TYPES } from 'config/loginTypes'
import * as routes from 'config/routes'
import AccessErrorScene from 'scenes/AccessErrorScene'
import AvailableCollectionDetailsScene from 'scenes/AvailableCollectionDetailsScene'
import AvailableCollectionsScene from 'scenes/AvailableCollectionsScene'
import BasketsScene from 'scenes/BasketsScene'
import DashboardScene from 'scenes/DashboardScene'
import DocumentsScene from 'scenes/DocumentsScene'
import ErrorScene from 'scenes/ErrorScene'
import FaqScene from 'scenes/FaqScene'
import InductionDetailsScene from 'scenes/InductionDetailsScene'
import InductionsListScene from 'scenes/InductionsListScene'
import JoinIntroScene from 'scenes/JoinIntroScene'
import LandingPageScene from 'scenes/LandingPageScene'
import LoginEmailRequestedScene from 'scenes/LoginEmailRequestedScene'
import LoginScene from 'scenes/LoginScene'
import MagicLoginScene from 'scenes/MagicLoginScene'
import MyCollectionDetailsScene from 'scenes/MyCollectionDetailsScene'
import MyCollectionsScene from 'scenes/MyCollectionsScene'
import NotificationPreferencesScene from 'scenes/NotificationPreferencesScene'
import RegistrationScene from 'scenes/RegistrationScene'
import StoresScene from 'scenes/StoresScene'
import ErrorBoundary from 'components/ErrorBoundary'
import GlobalDataFetcher from 'components/GlobalDataFetcher'
import AuthenticatedLayout from 'components/layout/AuthenticatedLayout'
import Layout from 'components/layout/Layout'
import QueryParamProviderWrapper from './QueryParamProviderWrapper'

const AppRoutes = () => (
  <>
    <Route element={<QueryParamProviderWrapper />}>
      <Route
        element={
          <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
            <ScrollRestoration getKey={(location) => location.pathname} />
            <GlobalDataFetcher />
          </Sentry.ErrorBoundary>
        }
      >
        <Route
          path={routes.BASKETS_URL}
          element={
            <RequireAuthentication>
              <BasketsScene />
            </RequireAuthentication>
          }
        />

        <Route
          element={
            <RequireAuthentication>
              <AuthenticatedLayout />
            </RequireAuthentication>
          }
        >
          <Route
            path={routes.DASHBOARD_URL}
            element={<DashboardScene />}
          />
          <Route
            path={routes.INDUCTIONS_URL}
            element={<InductionsListScene />}
          />
          <Route
            path={`${routes.INDUCTIONS_URL}/:inductionId`}
            element={<InductionDetailsScene />}
          />
          <Route
            path={routes.MY_COLLECTIONS_URL}
            element={<MyCollectionsScene />}
          />
          <Route
            path={`${routes.MY_COLLECTIONS_URL}/:collectionId`}
            element={<MyCollectionDetailsScene />}
          />
          <Route
            path={`${routes.AVAILABLE_COLLECTIONS_URL}/:collectionId`}
            element={<AvailableCollectionDetailsScene />}
          />
          <Route
            path={routes.AVAILABLE_COLLECTIONS_URL}
            element={<AvailableCollectionsScene />}
          />
          <Route
            path={routes.STORES_URL}
            element={<StoresScene />}
          />
          <Route
            path={routes.FAQ_URL}
            element={<FaqScene />}
          />
          <Route
            path={routes.DOCUMENTS_URL}
            element={<DocumentsScene />}
          />
          <Route
            path={routes.NOTIFICATIONS_URL}
            element={<NotificationPreferencesScene />}
          />
        </Route>

        <Route element={<Layout />}>
          <Route
            path={routes.LOGIN_URL}
            element={
              <RequireNoAuthentication>
                <LoginScene loginType={LOGIN_TYPES.PASSWORD} />
              </RequireNoAuthentication>
            }
          />
          <Route
            path={routes.LOGIN_MAGIC_URL}
            element={
              <RequireNoAuthentication>
                <LoginScene loginType={LOGIN_TYPES.MAGIC} />
              </RequireNoAuthentication>
            }
          />
          <Route
            path={routes.LOGIN_EMAIL_REQUESTED_URL}
            element={<LoginEmailRequestedScene />}
          />
          <Route
            path={`${routes.LOGIN_URL}/:token`}
            element={<MagicLoginScene />}
          />
          <Route
            path={routes.REGISTER_URL}
            element={
              <RequireFoodHeroRegistrationAccess>
                <RegistrationScene />
              </RequireFoodHeroRegistrationAccess>
            }
          />
          <Route
            path={routes.JOIN_INTRO_URL}
            element={
              <RequireNonFoodHeroAccess>
                <JoinIntroScene />
              </RequireNonFoodHeroAccess>
            }
          />
          <Route
            path={`${routes.ACCESS_ERROR_URL}/:errorType`}
            element={<AccessErrorScene />}
          />
          <Route
            path={`${routes.ERROR_URL}/:errorCode`}
            element={<ErrorScene />}
          />
          <Route
            path="/"
            element={
              <LandingPageRedirects>
                <LandingPageScene />
              </LandingPageRedirects>
            }
          />
          <Route
            path="*"
            element={<ErrorScene />}
          />
        </Route>
      </Route>
    </Route>
  </>
)

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
export const router = sentryCreateBrowserRouter(createRoutesFromElements(AppRoutes()))

const App = () => <RouterProvider router={router} />

export default App
