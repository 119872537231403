export const DESKTOP_BREAKPOINT = 992
export const TABLET_BREAKPOINT = 600
export const MIDSIZE_BREAKPOINT = 1300

export const isWideScreen = () => {
  const width = window.innerWidth
  return width >= DESKTOP_BREAKPOINT
}

export const isMidsizeScreen = () => {
  const width = window.innerWidth
  return width >= TABLET_BREAKPOINT && width <= MIDSIZE_BREAKPOINT
}
