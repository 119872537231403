import { useAppSelector } from '@redux/hooks'
import { selectCollectionWithStoreAndBusinessById } from '@redux/selectors/collections'
import { selectCollectionWindows } from '@redux/selectors/collections'
import { FETCHING_STATUSES } from 'config/api'
import { COLLECTION_TYPES } from 'config/collections'
import { getUpcomingOccurrences } from 'helpers/collections'

const { IDLE, LOADING } = FETCHING_STATUSES

type UseCollectionType = {
  collectionId: number | string
  dataToInclude?: object
}

const useCollection = ({ collectionId, dataToInclude }: UseCollectionType) => {
  const collection: CollectionType = useAppSelector((state) =>
    selectCollectionWithStoreAndBusinessById(state, collectionId, dataToInclude),
  )
  const { windowId } = collection || {}

  const collectionWindows = useAppSelector(selectCollectionWindows)

  const isLoadingCollections = useAppSelector(
    (state) =>
      state.collections.collectionsFetchingStatus === IDLE ||
      state.collections.collectionsFetchingStatus === LOADING,
  )
  const isLoadingStores = useAppSelector(
    (state) =>
      state.stores.storesFetchingStatus === IDLE || state.stores.storesFetchingStatus === LOADING,
  )
  const isLoadingBusinesses = useAppSelector(
    (state) =>
      state.stores.businessesFetchingStatus === IDLE ||
      state.stores.businessesFetchingStatus === LOADING,
  )
  const isLoading =
    isLoadingCollections === true || isLoadingStores === true || isLoadingBusinesses === true

  const getWindowInfo = () => {
    const collectionWindow = Object.entries(collectionWindows).find(
      ([_windowId, collections]: [string, CollectionType[]]) =>
        collections.find((collection) => collection.collectionId === collectionId),
    )
    if (collectionWindow) {
      const [_windowId, collections] = collectionWindow as [string, CollectionType[]]
      const ongoingCollection = collections.find(
        (collection) => collection.type === COLLECTION_TYPES.ONGOING,
      )
      const firstUpcomingCollection = collections.reduce((prev, current) =>
        new Date(current.nextCollection) < new Date(prev.nextCollection) ? current : prev,
      )

      return {
        windowId,
        upcomingOccurrences: getUpcomingOccurrences(collections),
        ongoingCollectionId: ongoingCollection?.collectionId,
        collectionNotes: firstUpcomingCollection?.notes || ongoingCollection?.notes,
      }
    }
    return {
      windowId,
      upcomingOccurrences: undefined,
      ongoingCollectionId: undefined,
      collectionNotes: undefined,
    }
  }
  return {
    data: collection,
    windowInfo: getWindowInfo(),
    isLoading,
    isLoadingCollections,
    isLoadingStores,
    isLoadingBusinesses,
  }
}

export default useCollection
