import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@redux/hooks'
import { formatDateToDayAndTime24 } from 'helpers/time'

type Props = {
  nextCollection: string
  userId?: number
  groupName: string
  value?: number
  isChecked: boolean
  isDisabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  timezone?: string
}

const TargetCollectionSelection = ({
  userId,
  nextCollection,
  value,
  groupName,
  isChecked,
  onChange,
  isDisabled,
  timezone,
}: Props) => {
  const { t } = useTranslation()
  const userName = useAppSelector((state) => state.users.items[userId]?.name)

  return (
    <li>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        <input
          className="with-gap"
          name={groupName}
          type="radio"
          value={value}
          checked={isChecked}
          onChange={onChange}
          disabled={isDisabled}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('your-collections.page.show.swap-modal.choices-modal.swap-for-slot', {
              name: userName,
              date: formatDateToDayAndTime24(nextCollection, timezone),
            }),
          }}
        />
      </label>
    </li>
  )
}

export default TargetCollectionSelection
