import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'

const fetchCollectionSize = async ({ queryKey }) => {
  const [_key, { storeId, weekdayIndex }] = queryKey
  const response = await fetch(
    `/api/v1/reporting_collection_dates/search?store_id=${storeId}&window_wday=${weekdayIndex}&has_articles=true&page=1&per_page=5`,
  )

  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`)
  }

  return response.json()
}

/**
 * @param storeId
 * @param weekdayIndex 0-6 (Sunday - Saturday)
 */
const useCollectionSize = ({ storeId, weekdayIndex }) => {
  return useQuery({
    queryKey: ['collectionSize', { storeId, weekdayIndex }],
    queryFn: fetchCollectionSize,
    select: (data) => {
      const filteredCollections = data.filter(
        (collection) => DateTime.fromISO(collection.start) < DateTime.now(),
      )
      return filteredCollections
    },
  })
}

export default useCollectionSize
