import { useTranslation } from 'react-i18next'
import { MdLink } from 'react-icons/md'
import { motion } from 'framer-motion'
import Card from 'components/common/Cards/Card'
import styles from './TownLeaderboardWidget.module.scss'

const TownLeaderboardWidget = () => {
  const { t } = useTranslation()

  return (
    <motion.div
      layout
      transition={{
        layout: { duration: 0.2 },
      }}
    >
      <Card className={styles.card}>
        <div className={styles.headingSection}>
          <Card.Heading>{t('dashboard-scene.town-leaderboard.heading')}</Card.Heading>
        </div>

        <Card.TextSection>{t('dashboard-scene.town-leaderboard.description')}</Card.TextSection>
        <span className={styles.linkAndIcon}>
          <span className={styles.icon}>
            <MdLink />
          </span>
          <a
            href={t('dashboard-scene.town-leaderboard.leaderboard-link')}
            className={styles.link}
          >
            {t('dashboard-scene.town-leaderboard.heading')}
          </a>
        </span>
      </Card>
    </motion.div>
  )
}

export default TownLeaderboardWidget
