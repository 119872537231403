import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { getSignedStaticMapUrl } from 'helpers/map'
import { useIsMobile } from 'hooks/application'
import AvailableCollectionInformation from 'components/AvailableCollections/AvailableCollectionInformation'
import styles from './DisplayedCollection.module.scss'

export type CollectionPropType = {
  storeLocation: {
    latitude: number
    longitude: number
  }
  homeLocation: {
    latitude: number
    longitude: number
  }
  nextCollection: string
  storeAddress: {
    addressLine1: string
    addressLine2: string
    postCode: string
  }
  businessName: string
  storeName?: string
  type: string
}

type DisplayedCollectionProps = {
  collection: CollectionPropType
}

const DisplayedCollection = ({ collection }: DisplayedCollectionProps) => {
  const isMobile = useIsMobile()
  const [mapUrl, setMapUrl] = useState()

  useEffect(() => {
    const getMapUrl = async () => {
      const url = await getSignedStaticMapUrl({
        latitude: collection.storeLocation.latitude,
        longitude: collection.storeLocation.longitude,
        width: 520,
        height: 157,
      })
      setMapUrl(url)
    }
    if (isMobile === false) {
      getMapUrl()
    }
  }, [isMobile, collection.storeLocation])

  return (
    <div className={clsx('card', styles.displayedCollection)}>
      <div className="card-content">
        <h3 className={styles.cardHeader}>
          <span>{collection.businessName}</span>
          {collection.storeName !== undefined && (
            <>
              <span> | </span>
              <span>{collection.storeName}</span>
            </>
          )}
        </h3>
        <AvailableCollectionInformation
          type={collection.type}
          storeLocation={collection.storeLocation}
          homeLocation={collection.homeLocation}
          nextCollection={collection.nextCollection}
          storeAddress={collection.storeAddress}
          businessName={collection.businessName}
          className={styles.availebleCollectionInformation}
        />
        {isMobile === false && mapUrl !== undefined && (
          <img
            className={styles.mapImage}
            width="520"
            height="157"
            alt=""
            src={mapUrl}
            data-testid="map-image"
          />
        )}
      </div>
    </div>
  )
}

export default DisplayedCollection
