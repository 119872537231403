import {
  COLLECTION_UNASSIGN_ERROR,
  COLLECTION_UNASSIGN_START,
  COLLECTION_UNASSIGN_SUCCESS,
} from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:unassign')

export default (windowId, selectedEndDate) => {
  return (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: COLLECTION_UNASSIGN_START,
      })

      const endDate = selectedEndDate === 'immediate' ? null : selectedEndDate
      const failedFetch = (error) => {
        const reason = loggableError(error)
        debug(`Failed unassigning from collection window ${windowId}`, reason)
        dispatch({
          type: COLLECTION_UNASSIGN_ERROR,
          payload: {
            errors: error?.response?.data?.errors?.base || [],
          },
          meta: {
            analytics: {
              eventName: COLLECTION_UNASSIGN_ERROR,
              eventPayload: {
                windowId,
                errors: error,
              },
            },
          },
        })
        reject(reason)
      }

      return axios
        .patch(`/api/v1/collection_windows/${windowId}/release`, {
          collection_window: {
            date: endDate,
          },
        })
        .then((response) => {
          const state = getState()

          const { date } = response.data
          const storeId = windowId.split('_')[0]
          const store = state.stores?.items[storeId]
          const { businessId, name: storeName } = store || {}
          const business = state.stores?.businesses[businessId]
          dispatch({
            type: COLLECTION_UNASSIGN_SUCCESS,
            payload: { selectedDate: selectedEndDate === 'immediate' ? null : date },
            meta: {
              analytics: {
                eventName: COLLECTION_UNASSIGN_SUCCESS,
                eventPayload: {
                  windowId,
                  type: 'ongoing',
                  businessId: businessId,
                  business: business?.name,
                  storeId,
                  store: storeName,
                  unassignDate: selectedEndDate,
                },
              },
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}
