import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import closeSkipModal from '@redux/thunks/collections/closeSkipModal'
import closeSwapModal from '@redux/thunks/collections/closeSwapModal'
import closeUnassignModal from '@redux/thunks/collections/closeUnassignModal'
import skip from '@redux/thunks/collections/skip'
import swap from '@redux/thunks/collections/swap'
import unassignCollection from '@redux/thunks/collections/unassignCollection'
import unassignWindow from '@redux/thunks/collections/unassignWindow'
import { modalTrackingKeys } from 'config/modals'
import {
  COLLECTION_ACTIONS,
  getActionModalKey,
  getCollectionActionDetails,
} from 'helpers/collections'
import Debug from 'helpers/debug'
import { useModal, useTimeout } from 'hooks/application'
import { useCollection } from 'hooks/collections'
import { DEBOUNCE_TIMEOUT } from 'components/Baskets/List/Content/Article/ArticleQuantity'
import Button from 'components/common/Button'
import { ModalFooter } from 'components/common/Modal/ConfirmationModal'

const { UNASSIGN, SKIP, SWAP } = COLLECTION_ACTIONS

type Props = {
  mode: string
}

const Footer = ({ mode }: Props) => {
  const debug = Debug(`javascript:components:collection-${mode}:options:footer`)
  const { t } = useTranslation()
  const { closeModal } = useModal(getActionModalKey(mode))

  const [isActionButtonPressed, setIsActionButtonPressed] = useState(false)

  const dispatch = useAppDispatch()

  const collection = useAppSelector((state) => state.collections[mode])
  const { selectedDate, selectedCollectionId, swapForCollectionId } = collection
  const { windowInfo } = useCollection({ collectionId: selectedCollectionId })
  const { windowInfo: swapForWindowInfo } = useCollection({ collectionId: swapForCollectionId })

  const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
    return getCollectionActionDetails(t)
  }, [])

  const isActionButtonDisabled = useMemo(() => {
    switch (mode) {
      case UNASSIGN:
        return selectedDate === undefined
      case SKIP:
        return selectedDate.length === 0
      case SWAP:
        return swapForCollectionId === undefined
      default:
        debug('Invalid collection action provided')
        break
    }
  }, [mode, selectedDate, swapForCollectionId])

  const handleCloseButtonClick = () => {
    switch (mode) {
      case UNASSIGN:
        closeModal()
        dispatch(closeUnassignModal())
        break
      case SKIP:
        closeModal()
        dispatch(closeSkipModal())
        break
      case SWAP:
        closeModal()
        dispatch(closeSwapModal())
        break
      default:
        break
    }
  }

  const handleActionClick = async () => {
    setIsActionButtonPressed(true)
    try {
      switch (mode) {
        case SKIP:
          dispatch(skip(windowInfo.windowId, selectedDate)).catch(debug)
          break
        case UNASSIGN:
          if (windowInfo.ongoingCollectionId) {
            dispatch(unassignWindow(windowInfo.windowId, selectedDate)).catch(debug)
          } else {
            dispatch(unassignCollection(selectedCollectionId, selectedDate)).catch(debug)
          }
          break
        case SWAP:
          dispatch(
            swap({
              windowId: windowInfo.windowId,
              swapForWindowId: swapForWindowInfo.windowId,
            }),
          ).catch(debug)
          break
        default:
          debug('Invalid collection action provided')
          break
      }
    } catch (error) {
      debug(error)
    }
  }

  useTimeout(
    () => {
      setIsActionButtonPressed(false)
    },
    DEBOUNCE_TIMEOUT,
    isActionButtonPressed === true,
  )

  return (
    <ModalFooter>
      <Button
        data-tracking={modalTrackingKeys.reject}
        onClick={handleCloseButtonClick}
        secondary
      >
        {COLLECTION_ACTIONS_DETAILS[mode].footer.closeButton}
      </Button>

      <Button
        data-tracking={modalTrackingKeys.confirm}
        disabled={isActionButtonDisabled || isActionButtonPressed}
        onClick={handleActionClick}
        testId={`modal-${mode?.toLowerCase()}-button`}
      >
        {COLLECTION_ACTIONS_DETAILS[mode].footer.confirmButton}
      </Button>
    </ModalFooter>
  )
}

export default Footer
