import { createSelector } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { selectBusinessById } from '@redux/selectors/businesses'
import { getCurrentUserId } from 'helpers/user'

const selectCollectionById = (state, id) => {
  return state.collections?.items[id]
}

const selectStoreById = (stores, id) => {
  return stores?.items[id]
}

const selectInductionsByIds = (inductions, ids) => {
  const inductionList = []
  ids?.forEach((inductionId) => {
    const induction = inductions.items[inductionId]
    if (induction !== undefined) {
      inductionList.push(induction)
    }
  })
  return inductionList
}

const selectCollectionsByStoreId = (collections, storeId) => {
  return collections.filter((collection) => collection.storeId === storeId)
}

const selectMyCollections = createSelector([(state) => state.collections.items], (collections) => {
  return Object.entries(collections)
    .filter(
      ([_collectionId, collection]: [string, any]) =>
        collection.userId === Number(getCurrentUserId()),
    )
    .map(([collectionId, collection]: [string, any]) => ({
      collectionId: Number(collectionId),
      ...collection,
    }))
})

const selectCollectionWithStoreAndBusinessById = createSelector(
  [
    (state) => state.stores,
    (state) => state.inductions,
    selectCollectionById,
    (_state, _collectionId, dataToInclude = undefined) => dataToInclude,
  ],
  (stores, inductions, collection, dataToInclude) => {
    const result = collection !== undefined ? { ...collection } : undefined
    const store = selectStoreById(stores, collection?.storeId)
    if (store !== undefined) {
      result.store = store
    }
    const business = selectBusinessById(stores, store?.businessId)
    if (business !== undefined) {
      result.business = business
    }

    if (result !== undefined && dataToInclude?.inductions === true) {
      const requiredInductions = selectInductionsByIds(
        inductions,
        collection?.requiredInductionsIds,
      )
      if (requiredInductions !== undefined) {
        result.requiredInductions = requiredInductions

        const completedInductions = requiredInductions.filter(
          (induction) => induction.isComplete === true,
        )
        if (completedInductions !== undefined) {
          result.completedInductions = completedInductions
        }
      }
    }

    return result
  },
)

const selectMyCollectionsWithStoreId = createSelector(
  [selectMyCollections, (_state, storeId) => storeId],
  (myCollections, storeId) => {
    return selectCollectionsByStoreId(myCollections, storeId)
  },
)

const selectMyCollectionsWithStoreInfo = createSelector(
  [(state) => state.stores, selectMyCollections],
  (stores, myCollections) => {
    if (myCollections.length === 0) {
      return []
    }

    return myCollections.map((collection) => {
      const collectionStoreId = collection.storeId
      const collectionStoreInfo = selectStoreById(stores, collectionStoreId)

      const businessId = collectionStoreInfo?.businessId
      const collectionBusinessInfo = businessId && selectBusinessById(stores, businessId)

      return {
        ...collection,
        store: collectionStoreInfo,
        business: collectionBusinessInfo,
      }
    })
  },
)

const selectCollectionWindows = createSelector(
  [selectMyCollectionsWithStoreInfo],
  (combinedCollections) => {
    const upcomingCollections = combinedCollections.filter(
      (collection) => DateTime.fromISO(collection.nextCollection) >= DateTime.now(),
    )

    const groupedCollections = upcomingCollections.reduce((acc, collection) => {
      const windowId = collection.windowId
      if (!acc[windowId]) {
        acc[windowId] = []
      }
      acc[windowId].push(collection)
      return acc
    }, {})
    return groupedCollections
  },
)

export {
  selectCollectionWithStoreAndBusinessById,
  selectMyCollections,
  selectMyCollectionsWithStoreId,
  selectMyCollectionsWithStoreInfo,
  selectCollectionWindows,
}
