import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import {
  selectMyCollectionsWithStoreInfo,
  selectCollectionWindows,
} from '@redux/selectors/collections'
import fetchMyCollections from '@redux/thunks/collections/fetchMyCollections'
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses'
import fetchStores from '@redux/thunks/stores/fetchStores'
import { getUpcomingOccurrences } from 'helpers/collections'
import Debug from 'helpers/debug'

const debug = Debug('hooks:collections:useFetchMyCollectionsWithStoreInfo')

export default () => {
  const dispatch = useAppDispatch()

  const collectionsWithStoreInfo = useAppSelector(selectMyCollectionsWithStoreInfo)
  const collectionWindows = useAppSelector(selectCollectionWindows)
  const collectionsFetchingStatus = useAppSelector(
    (state) => state.collections.collectionsFetchingStatus,
  )

  const stores = useAppSelector((state) => state.stores.items)
  const storeIds = useMemo(() => Object.keys(stores), [stores])
  const businessIds = useMemo(() => {
    const ids = []
    Object.values(stores).forEach((store: any) => {
      if (store.businessId !== undefined) {
        ids.push(store.businessId)
      }
    })
    return ids
  }, [stores])

  const sortedCollectionsWithStoreInfo = useMemo(
    () =>
      collectionsWithStoreInfo.sort((a, b) => {
        const dateA = new Date(a.nextCollection).valueOf()
        const dateB = new Date(b.nextCollection).valueOf()
        return dateA - dateB
      }),
    [collectionsWithStoreInfo],
  )

  const sortedCombinedCollections = useMemo(() => {
    const collectionsList = []
    Object.values(collectionWindows).forEach((collectionWindow: CollectionType[]) => {
      const ongoingCollection = collectionWindow.find((collection) => collection.type === 'ongoing')
      if (ongoingCollection) {
        collectionsList.push({
          ...ongoingCollection,
          nextWindowDate: getUpcomingOccurrences(collectionWindow)[0],
        })
      } else {
        collectionWindow.forEach((collection) => collectionsList.push({ ...collection }))
      }
    })

    return collectionsList.sort((a, b) => {
      const dateA = new Date(a.nextWindowDate || a.nextCollection).valueOf()
      const dateB = new Date(b.nextWindowDate || b.nextCollection).valueOf()
      return dateA - dateB
    })
  }, [collectionWindows])

  useEffect(() => {
    dispatch(fetchMyCollections()).catch(debug)
  }, [])

  useEffect(() => {
    if (storeIds !== undefined && storeIds.length > 0) {
      dispatch(fetchStores(storeIds)).catch(debug)
    }
  }, [storeIds.length])

  useEffect(() => {
    if (businessIds !== undefined && businessIds.length > 0) {
      dispatch(fetchBusinesses(businessIds)).catch(debug)
    }
  }, [businessIds.length])

  return {
    sortedCollectionsWithStoreInfo,
    sortedCombinedCollections,
    fetchingStatus: collectionsFetchingStatus,
  }
}
