import { useEffect, useMemo } from 'react'
import { shallowEqual } from 'react-redux'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectMyCollectionsWithStoreId } from '@redux/selectors/collections'
import { selectCollectionWindows } from '@redux/selectors/collections'
import fetchMyCollections from '@redux/thunks/collections/fetchMyCollections'
import { COLLECTION_TYPES } from 'config/collections'
import { getUpcomingOccurrences } from 'helpers/collections'
import Debug from 'helpers/debug'

const debug = Debug('components:collection-unassign:options:step:step-content')

const useFetchMyStoreCollections = ({ onlyOngoing, selectedStoreId }) => {
  const dispatch = useAppDispatch()
  const collectionWindows = useAppSelector(selectCollectionWindows)

  const collectionsWithStoreId = useAppSelector(
    (state) => selectMyCollectionsWithStoreId(state, selectedStoreId),
    shallowEqual,
  )

  const selectedStoreCollections = useMemo(() => {
    if (onlyOngoing === true) {
      return collectionsWithStoreId.filter(
        (collection) => collection.type === COLLECTION_TYPES.ONGOING,
      )
    }

    return collectionsWithStoreId
  }, [collectionsWithStoreId, onlyOngoing])

  const selectedStoreTimezone = useAppSelector(
    (state) => state.stores?.items[selectedStoreId]?.timezone,
  )

  const combinedStoreCollections = useMemo(() => {
    const collectionsList = []

    Object.values(collectionWindows).forEach((collectionWindow: CollectionType[]) => {
      const windowHasMatchingCollections = selectedStoreCollections.some((collection) =>
        collectionWindow.some(
          (windowCollection) => collection.collectionId === windowCollection.collectionId,
        ),
      )

      if (!windowHasMatchingCollections) {
        return
      }
      const ongoingCollection = collectionWindow.find((collection) => collection.type === 'ongoing')
      if (ongoingCollection) {
        collectionsList.push({
          ...ongoingCollection,
          nextWindowDate: getUpcomingOccurrences(collectionWindow)[0],
        })
      } else {
        collectionWindow.forEach((collection) => collectionsList.push({ ...collection }))
      }
    })

    return collectionsList
  }, [selectedStoreCollections, collectionWindows])

  useEffect(() => {
    if (selectedStoreId) {
      dispatch(fetchMyCollections({ storeId: selectedStoreId })).catch(debug)
    }
  }, [selectedStoreId])

  return { combinedStoreCollections, selectedStoreCollections, selectedStoreTimezone }
}

export default useFetchMyStoreCollections
