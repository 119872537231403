import clsx from 'clsx'

type Props = {
  variant?: 'coral'
  children: React.ReactNode
}

const Pill = ({ variant, children }: Props) => {
  return <div className={clsx(variant, 'pill')}>{children}</div>
}

Pill.Icon = function PillIcon({ children }) {
  return <span className="pill-icon">{children}</span>
}

Pill.Text = function PillText({ children }) {
  return <div className="pill-text">{children}</div>
}

export default Pill
