import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdNotificationsNone } from 'react-icons/md'
import { useParams, useSearchParams } from 'react-router-dom'
import MyCollectionWrapper from 'components/Collections/MyCollectionWrapper'
import Alert from 'components/common/Alert'
import SceneHeader from 'components/common/SceneHeader'
import { Stack } from 'components/layout/primitives'
import styles from './MyCollectionDetailsScene.module.scss'

const NEW_COLLECTION_QUERY_PARAM = 'new'

const MyCollectionDetailsScene = () => {
  const { t } = useTranslation()
  const { collectionId } = useParams()
  const [searchParams] = useSearchParams()
  const isAlertVisible = searchParams.get(NEW_COLLECTION_QUERY_PARAM) === 'true'

  return (
    <div id="my-collection-details-scene">
      <SceneHeader title={t('your_collections.show.title')} />
      <div className="scene-description">{t('your_collections.show.description')}</div>
      <Stack gap="1.5rem">
        {isAlertVisible && (
          <Alert
            variant="message"
            className={styles.alert}
          >
            <div className={styles.messageHeader}>
              <MdNotificationsNone className="alert-icon" />
              <h3>{t('your_collections.show.steps.title')}</h3>
            </div>
            <ol>
              <li>
                <p>
                  <a
                    className={styles.faqLink}
                    href={t('your_collections.show.steps.read_faq_url')}
                  >
                    {t('your_collections.show.steps.read_faq')}
                  </a>
                </p>
              </li>
              <li>
                <p>{t('your_collections.show.steps.update_profile')}</p>
              </li>
              <li>
                <p>{t('your_collections.show.steps.introduce_yourself')}</p>
              </li>
            </ol>
          </Alert>
        )}
        <MyCollectionWrapper collectionId={Number(collectionId)} />
      </Stack>
    </div>
  )
}

export default memo(MyCollectionDetailsScene)
