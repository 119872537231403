import {
  COLLECTION_SKIP_ERROR,
  COLLECTION_SKIP_START,
  COLLECTION_SKIP_SUCCESS,
} from '@redux/actionTypes'
import { trackEvent } from 'services/Analytics'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:skip')

export default (windowId, dates) => {
  return (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: COLLECTION_SKIP_START,
      })

      const failedFetch = (error) => {
        const reason = loggableError(error)
        debug(`Failed skipping collection window ${windowId}`, reason)
        dispatch({
          type: COLLECTION_SKIP_ERROR,
          payload: {
            errors: error?.response?.data?.errors?.base || [],
          },
          meta: {
            analytics: {
              eventName: COLLECTION_SKIP_ERROR,
              eventPayload: {
                windowId,
                errors: error?.response?.data?.errors?.base || [],
              },
            },
          },
        })
        reject(reason)
      }

      return axios
        .patch(`/api/v1/collection_windows/${windowId}/skip`, {
          dates,
        })
        .then((response) => {
          const selectedDate = response.data?.map((collection) => collection.end)
          const state = getState()

          const storeId = windowId.split('_')[0]
          const store = state.stores?.items[storeId]
          const { businessId, name: storeName } = store || {}
          const business = state.stores?.businesses[businessId]

          dispatch({
            type: COLLECTION_SKIP_SUCCESS,
            payload: { selectedDate },
            meta: {
              analytics: {
                eventName: COLLECTION_SKIP_SUCCESS,
                eventPayload: {
                  windowId,
                  businessId: businessId,
                  business: business?.name,
                  storeId,
                  store: storeName,
                  skippedDates: selectedDate,
                  skippedDatesCount: selectedDate?.length,
                },
              },
            },
          })

          //trackEvent('COLLECTION_SKIP_NEXT', { id: collectionId })

          resolve()
        })
        .catch(failedFetch)
    })
  }
}

export const legacy = (collectionId) => {
  return (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: COLLECTION_SKIP_START,
      })

      const failedFetch = (error) => {
        const reason = loggableError(error)
        debug(`Failed skipping collection ${collectionId}`, reason)
        dispatch({
          type: COLLECTION_SKIP_ERROR,
          payload: {
            errors: error?.response?.data?.errors?.base || [],
          },
        })
        reject(reason)
      }

      return axios
        .patch(`/api/v1/collections/${collectionId}/pause`, {
          collection: {
            end: 'immediate',
          },
        })
        .then(() => {
          const state = getState()
          const collection = state.collections?.items[collectionId]
          const store = state.stores?.items[collection.storeId]
          const business = state.stores?.businesses[store.businessId]
          dispatch({
            type: COLLECTION_SKIP_SUCCESS,
            meta: {
              analytics: {
                eventName: COLLECTION_SKIP_SUCCESS,
                eventPayload: {
                  id: collectionId,
                  type: collection?.type,
                  business: business?.name,
                  store: store?.name,
                  nextCollection: collection?.nextCollection,
                },
              },
            },
          })

          trackEvent('COLLECTION_SKIP_NEXT', { id: collectionId })

          resolve()
        })
        .catch(failedFetch)
    })
  }
}
