/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import type { ChangeEventHandler } from 'react'
import clsx from 'clsx'
import Caret from 'images/caret.svg'
import styles from './Dropdown.module.scss'

type DropdownType = {
  name: string
  placeholder?: string
  options: {
    value: string
    label: string
  }[]
  onChange: ChangeEventHandler<HTMLSelectElement>
  onBlur?: () => void
  value?: string
  className?: string
}

type RefType = HTMLSelectElement

const Dropdown = forwardRef<RefType, DropdownType>(
  ({ name, placeholder, options, onChange, onBlur, value, className }: DropdownType, ref) => {
    return (
      <div className={styles.dropdownWrapper}>
        <select
          id={name}
          className={clsx(styles.dropdown, className)}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          style={{ display: 'block' }}
        >
          {placeholder && (
            <option
              data-testid="placeholder"
              hidden
              disabled
            >
              {placeholder}
            </option>
          )}

          {options.map((option, index) => (
            <option
              key={`${index}-${option.value}`}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
        <img
          src={Caret}
          className={styles.dropdownIcon}
          alt=""
        />
      </div>
    )
  },
)

export default Dropdown
