import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineSchedule } from 'react-icons/md'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { trackEvent } from 'services/Analytics'
import { MY_COLLECTIONS_URL } from 'config/routes'
import { formatShortDateAndTime24 } from 'helpers/time'
import EmptyStateCollectionCard from 'images/EmptyStateCollectionCard.png'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import StoreInfo from 'components/common/StoreInfo/StoreInfo'
import styles from './CollectionCard.module.scss'

type Props = {
  collection?: {
    collectionId: string
    business: any
    store: any
    storeId: number
    nextCollection: string
    nextWindowDate: string
  }
  index?: number
}

const CollectionCard = ({ collection, index }: Props) => {
  const { t } = useTranslation()
  const { collectionId, business, store, storeId, nextCollection, nextWindowDate } =
    collection || {}

  const nextCollectionDate = useMemo(() => {
    const nextDate = nextWindowDate || nextCollection
    if (collection !== undefined) {
      return formatShortDateAndTime24(nextDate, store?.timezone)
    }
  }, [collection, nextCollection, nextWindowDate, store?.timezone])

  return (
    <article
      className={clsx(
        collection === undefined
          ? styles.noCollectionContainer
          : [styles.collectionContainer, 'card'],
      )}
      data-testid={index !== undefined && `collection-card-${index}`}
    >
      {collection === undefined ? (
        <img
          alt=""
          src={EmptyStateCollectionCard}
        />
      ) : (
        <Link
          className={styles.cardLink}
          to={`${MY_COLLECTIONS_URL}/${collectionId}`}
          onClick={() => trackEvent('FWH_DASHBOARD_CLICK_INDIVIDUAL_COLLECTION')}
        >
          <StoreInfo
            logoUrl={business?.logo.url}
            location={store?.location ?? {}}
            storeId={storeId}
            small
            addressLink={false}
          />
          <div className={styles.collectionInfo}>
            <span className={styles.icon}>
              <MdOutlineSchedule />
            </span>
            <SetInnerHtmlSafe>
              {t('dashboard-scene.your-collection-slots.collection-card.next-collection', {
                date: `<strong>${nextCollectionDate}</strong>`,
              })}
            </SetInnerHtmlSafe>
          </div>
        </Link>
      )}
    </article>
  )
}

export default CollectionCard
