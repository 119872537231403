import { Outlet } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import {
  filters,
  filtersDefaults,
} from 'components/AvailableCollections/AvailableCollectionsFilters/constants'

const options = {
  params: {
    [filters.storeid]: filtersDefaults.storeid,
    [filters.businessid]: filtersDefaults.businessid,
    [filters.type]: filtersDefaults.type,
    [filters.sort]: filtersDefaults.sort,
    [filters.distance]: filtersDefaults.distance,
    [filters.time]: filtersDefaults.time,
    [filters.dateRange]: filtersDefaults.dateRange,
  },
}
const QueryParamProviderWrapper = () => {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={options}
    >
      <Outlet />
    </QueryParamProvider>
  )
}

export default QueryParamProviderWrapper
