import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { DateTime } from 'luxon'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import setActiveStep from '@redux/thunks/collections/setActiveStep'
import setSelectedOptions from '@redux/thunks/collections/setSelectedOptions'
import { COLLECTION_TYPES } from 'config/collections'
import {
  COLLECTION_ACTIONS,
  STEP_COLLECTION,
  STEP_DATE,
  STEP_TARGET_COLLECTION,
  formatCollectionFullDate,
  getCollectionActionDetails,
  getSwappableCollections,
  getCollectionTypeTranslation,
} from 'helpers/collections'
import Debug from 'helpers/debug'
import { formatDateToDayAndTime24 } from 'helpers/time'
import useCollection from 'hooks/collections/useCollection'
import useFetchMyStoreCollections from 'components/Collections/CollectionActions/hooks/useFetchMyStoreCollections'
import Checkbox from 'components/common/Checkbox'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import StepContentSelection from './StepContentSelection'
import TargetCollectionSelection from './TargetCollectionSelection'

const EFFECTIVE_IMMEDIATELY = 'immediate'
const { UNASSIGN, SKIP, SWAP } = COLLECTION_ACTIONS

type Props = {
  step?: string
  mode?: string
}

const StepContent = ({ step, mode }: Props) => {
  const debug = Debug(`javascript:components:collection-${mode}:options:step:step-content`)
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
    return getCollectionActionDetails(t)
  }, [])

  const {
    selectedDate,
    selectedCollectionId,
    step: activeStep,
    swapForCollectionId,
    selectedStoreId,
  } = useAppSelector((state) => state.collections[mode])

  const { data, windowInfo } = useCollection({ collectionId: selectedCollectionId })
  const { nextCollection: selectedCollectionDate, endDate, type } = data || {}
  const { combinedStoreCollections, selectedStoreTimezone } = useFetchMyStoreCollections({
    onlyOngoing: mode !== UNASSIGN,
    selectedStoreId,
  })

  const storeCollectionIds = useAppSelector(
    (state) => state.stores?.items[selectedStoreId]?.collections,
  )
  const allCollections = useAppSelector((state) => state.collections?.items)

  const selectedStoreAllCollections = useMemo(() => {
    if (storeCollectionIds !== undefined && allCollections !== undefined) {
      return storeCollectionIds.map((collectionId) => ({
        ...allCollections[collectionId],
        id: collectionId,
      }))
    }
  }, [storeCollectionIds, allCollections])

  const swapForCollection = useAppSelector(
    (state) => state.collections?.items[swapForCollectionId] ?? {},
    shallowEqual,
  )

  const activeStepPosition = COLLECTION_ACTIONS_DETAILS[mode].steps[activeStep].position

  const isActiveStep = (step) => {
    return activeStepPosition === COLLECTION_ACTIONS_DETAILS[mode].steps[step].position
  }

  const getCollectionText = ({ collectionDate, collectionType = undefined }) => {
    if (collectionType === COLLECTION_TYPES.ADHOC) {
      return `${DateTime.fromISO(collectionDate, {
        zone: selectedStoreTimezone,
        locale: i18n.language,
      }).toFormat(
        'cccc dd MMM, HH:mm',
      )} - ${getCollectionTypeTranslation({ t, type: collectionType })}`
    }
    return formatDateToDayAndTime24(collectionDate, selectedStoreTimezone)
  }

  const slotList = () => {
    return combinedStoreCollections.map((option, index) => (
      <StepContentSelection
        groupName={`${STEP_COLLECTION}-${mode}`}
        value={option?.collectionId}
        isChecked={selectedCollectionId === option?.collectionId}
        onChange={updateSelectedCollectionId}
        displayComponent={
          <span>
            {getCollectionText({
              collectionDate: option?.nextCollection,
              collectionType: option?.type,
            })}
          </span>
        }
        key={index}
      />
    ))
  }

  const inactiveStepSlotContent = () => (
    <StepContentSelection
      groupName={`${STEP_COLLECTION}-${mode}`}
      value={selectedCollectionDate}
      isChecked
      isDisabled
      displayComponent={
        <span>
          {getCollectionText({
            collectionDate: selectedCollectionDate,
            collectionType: type,
          })}
        </span>
      }
    />
  )

  const inactiveStepDateContent = () => {
    const isValidDate = DateTime.fromISO(selectedDate).isValid
    if (selectedDate === undefined) {
      return null
    }
    return (
      <StepContentSelection
        groupName={`${STEP_DATE}-${mode}`}
        value={selectedDate}
        isChecked
        isDisabled
        displayComponent={
          <SetInnerHtmlSafe>
            {isValidDate
              ? formatCollectionFullDate({ date: selectedDate, timezone: selectedStoreTimezone })
              : COLLECTION_ACTIONS_DETAILS[mode].steps[STEP_DATE].immediateEffectText}
          </SetInnerHtmlSafe>
        }
      />
    )
  }

  const inactiveStepTargetCollection = () => {
    if (swapForCollectionId === undefined) {
      return null
    }
    return (
      <TargetCollectionSelection
        groupName={`${STEP_TARGET_COLLECTION}-${mode}`}
        value={swapForCollection.id}
        isChecked
        isDisabled
        onChange={updateTargetCollectionId}
        nextCollection={selectedCollectionDate}
        userId={swapForCollection.userId}
        key={swapForCollectionId}
        timezone={selectedStoreTimezone}
      />
    )
  }

  const immediateOption = () => (
    <StepContentSelection
      groupName={`${STEP_COLLECTION}-${mode}`}
      value={EFFECTIVE_IMMEDIATELY}
      isChecked={selectedDate === EFFECTIVE_IMMEDIATELY}
      onChange={updateSelectedDate}
      displayComponent={
        <span
          dangerouslySetInnerHTML={{
            __html: COLLECTION_ACTIONS_DETAILS[mode].steps[step].immediateEffectText,
          }}
        />
      }
    />
  )

  const endDateOptions = () =>
    windowInfo.upcomingOccurrences.slice(0, 3).map((option, index) => {
      return (
        <StepContentSelection
          groupName={`${STEP_COLLECTION}-${mode}`}
          value={option}
          isChecked={selectedDate === option}
          onChange={updateSelectedDate}
          displayComponent={
            <span>
              {formatCollectionFullDate({ date: option, timezone: selectedStoreTimezone })}
            </span>
          }
          key={index}
        />
      )
    })

  const displaySkipDates = () => {
    return windowInfo.upcomingOccurrences?.slice(0, 6).map((option, index) => {
      return (
        <li key={index}>
          <Checkbox
            checked={(selectedDate || []).includes(option)}
            testId={`option-${option}`}
            label={formatCollectionFullDate({
              date: option,
              timezone: selectedStoreTimezone,
            })}
            onChange={updateSelectedDate}
            value={option}
          />
        </li>
      )
    })
  }

  const displayMoreEndDates = () => {
    if (selectedCollectionDate === endDate && !windowInfo.ongoingCollectionId) {
      return null
    }
    return windowInfo.upcomingOccurrences !== undefined && endDateOptions()
  }

  const targetCollectionList = () => {
    if (selectedStoreAllCollections === undefined) {
      return null
    }
    const swappableCollections = getSwappableCollections(selectedStoreAllCollections)
    return swappableCollections.map((targetCollection) => {
      return (
        <TargetCollectionSelection
          groupName={`${STEP_TARGET_COLLECTION}-${mode}`}
          value={targetCollection.id}
          userId={targetCollection.userId}
          isChecked={targetCollection.id === swapForCollectionId}
          onChange={updateTargetCollectionId}
          nextCollection={targetCollection.nextCollection}
          key={targetCollection.id}
          timezone={selectedStoreTimezone}
        />
      )
    })
  }

  const updateSelectedCollectionId = (event) => {
    const collectionId = parseInt(event.target.value, 10)
    debug(`Selecting collection ${collectionId}`)
    try {
      dispatch(
        setSelectedOptions({
          action: mode,
          collectionId,
        }),
      )
      if (mode === SWAP) {
        dispatch(setActiveStep({ action: mode, step: STEP_TARGET_COLLECTION }))
      } else {
        dispatch(setActiveStep({ action: mode, step: STEP_DATE }))
      }
    } catch (error) {
      debug(error)
    }
  }

  const updateSelectedDate = (event) => {
    const date = event.target.value
    debug(`Clicked date ${date}`)

    try {
      let newDateSelection = date
      if (mode === SKIP) {
        const isChecked = event.target.checked
        let updatedDates = [...selectedDate]

        if (isChecked) {
          updatedDates.push(date)
        } else {
          updatedDates = updatedDates.filter((d) => d !== date)
        }
        newDateSelection = updatedDates
      }
      dispatch(
        setSelectedOptions({
          action: mode,
          selectedDate: newDateSelection,
        }),
      )
    } catch (error) {
      debug(error)
    }
  }

  const updateTargetCollectionId = (event) => {
    const targetCollectionId = Number(event.target.value)
    debug(`Selecting collection ${targetCollectionId}`)
    try {
      dispatch(
        setSelectedOptions({
          action: mode,
          swapForCollectionId: targetCollectionId,
        }),
      )
    } catch (error) {
      debug(error)
    }
  }

  const getSkipDescription = () => {
    return (
      <>
        <p>{t('your-collections.page.show.skip-modal.initial-modal.description-1')}</p>
        <p>{t('your-collections.page.show.skip-modal.initial-modal.description-2')}</p>
      </>
    )
  }

  return (
    <div className="step-content">
      {step === STEP_COLLECTION && (
        <form>
          <fieldset>
            <ul>
              {isActiveStep(STEP_COLLECTION) === true ? slotList() : inactiveStepSlotContent()}
            </ul>
          </fieldset>
        </form>
      )}
      {step === STEP_DATE && (
        <form>
          <fieldset>
            <ul>
              {isActiveStep(STEP_DATE) === true && mode === UNASSIGN && (
                <>
                  {immediateOption()}
                  {displayMoreEndDates()}
                </>
              )}
              {isActiveStep(STEP_DATE) === true && mode === SKIP && displaySkipDates()}
              {isActiveStep(STEP_DATE) === false && inactiveStepDateContent()}
              {mode === SKIP && getSkipDescription()}
            </ul>
          </fieldset>
        </form>
      )}
      {step === STEP_TARGET_COLLECTION && (
        <form>
          <fieldset>
            <ul>
              {isActiveStep(STEP_TARGET_COLLECTION) === true
                ? targetCollectionList()
                : inactiveStepTargetCollection()}
            </ul>
          </fieldset>
        </form>
      )}
    </div>
  )
}

export default StepContent
