import { ArrayParam, StringParam, withDefault } from 'use-query-params'
import { COLLECTION_TYPES } from 'config/collections'

export const sortByFilterKeys = {
  soonest: 'soonest',
  closest: 'closest',
}
export const collectionTypeFilterKeys = {
  ALL: 'all',
  ...COLLECTION_TYPES,
}
export const businessFilterKeys = {
  all: 'all',
}

export const storeFilterKeys = {
  all: 'all',
}

export const distanceFilterKeys = {
  all: 'all',
  3: '3',
  5: '5',
  10: '10',
  15: '15',
} as const

export const timeFilterKeys = {
  morning: 'morning',
  afternoon: 'afternoon',
  earlyEvening: 'earlyEvening',
  evening: 'evening',
} as const

export type DistanceFilterKeys = keyof typeof distanceFilterKeys
export type TimeFilterKeys = keyof typeof timeFilterKeys

const StoreIdParam = withDefault(StringParam, storeFilterKeys.all)
const BusinessIdParam = withDefault(StringParam, businessFilterKeys.all)
const TypeParam = withDefault(StringParam, collectionTypeFilterKeys.ALL)
const SortParam = withDefault(StringParam, sortByFilterKeys.soonest)
const DistanceParam = withDefault(StringParam, distanceFilterKeys.all)
const TimeParam = withDefault(ArrayParam, [
  timeFilterKeys.morning,
  timeFilterKeys.afternoon,
  timeFilterKeys.earlyEvening,
  timeFilterKeys.evening,
])
const DateRangeParam = withDefault(ArrayParam, null)

export const filtersDefaults = {
  storeid: StoreIdParam,
  businessid: BusinessIdParam,
  type: TypeParam,
  sort: SortParam,
  distance: DistanceParam,
  time: TimeParam,
  dateRange: DateRangeParam,
}

export const filters = {
  storeid: 'storeid',
  businessid: 'businessid',
  type: 'type',
  sort: 'sort',
  distance: 'distance',
  time: 'time',
  dateRange: 'dateRange',
}
