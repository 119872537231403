const modalKeys = {
  CLOSE_BASKET_CONFIRMATION: 'closeBasketConfirmation',
  VOLUNTEER_AGREEMENT: 'volunteerAgreement',
  FOOD_SAFETY_PROCEDURES: 'foodSafetyProcedures',
  UNASSIGN_COLLECTION: 'unassignCollection',
  SKIP_COLLECTION: 'skipCollection',
  SWAP_COLLECTION: 'swapCollection',
  CLAIM_COLLECTION: 'claimCollection',
  CLAIM_COLLECTION_SUCCESS: 'claimCollectionSuccess',
  SQUAD_CAPTAIN_AGREEMENT: 'squadCaptainAgreement',
}

const CONFIRM = 'modal_confirm_action'
const REJECT = 'modal_reject_action'
const modalTrackingKeys = {
  confirm: CONFIRM,
  reject: REJECT,
}

const modalTrackingEvents = {
  [CONFIRM]: 'MODAL_CONFIRM',
  [REJECT]: 'MODAL_REJECT',
}

export { modalKeys, modalTrackingKeys, modalTrackingEvents }
