import {
  COLLECTION_SWAP_ERROR,
  COLLECTION_SWAP_START,
  COLLECTION_SWAP_SUCCESS,
} from '@redux/actionTypes'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'

const debug = Debug('redux:thunks:collections:swap')

export default ({ windowId, swapForWindowId }) => {
  return (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: COLLECTION_SWAP_START,
      })

      const failedFetch = (error) => {
        const reason = loggableError(error)
        debug(`Failed to swap window ${windowId} whith ${swapForWindowId}`, reason)
        dispatch({
          type: COLLECTION_SWAP_ERROR,
          payload: {
            errors: error?.response?.data?.errors?.base || [],
          },
          meta: {
            analytics: {
              eventName: COLLECTION_SWAP_ERROR,
              eventPayload: {
                windowId,
                swapForWindowId,
                errors: error?.response?.data?.errors?.base || [],
              },
            },
          },
        })
        reject(reason)
      }

      return axios
        .post(`/api/v1/collection_windows/swap`, {
          collection_window: {
            their_window_id: swapForWindowId,
            my_window_id: windowId,
          },
        })
        .then((response) => {
          const newCollectionIds = response.data?.map((collection) => collection.id)
          const state = getState()
          const currentUserId = state.users.currentUser.id
          const storeId = swapForWindowId.split('_')[0]

          const store = state.stores?.items[storeId]
          const { businessId, name: storeName } = store || {}

          const business = state.stores?.businesses[businessId]
          const oldCollectionIds = Object.keys(state.collections?.items || {})
            .filter((collectionId) => {
              const collection = state.collections.items[collectionId]
              return collection.windowId === windowId && collection.userId === currentUserId
            })
            .map(Number)
          dispatch({
            type: COLLECTION_SWAP_SUCCESS,
            payload: {
              oldCollectionIds,
              newCollectionIds,
              storeId,
            },
            meta: {
              analytics: {
                eventName: COLLECTION_SWAP_SUCCESS,
                eventPayload: {
                  originalWindowId: windowId,
                  newWindowId: swapForWindowId,
                  businessId: businessId,
                  business: business?.name,
                  store: storeName,
                  storeId,
                },
              },
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}

export const legacy = ({
  selectedCollectionId: collectionId,
  swapForCollectionId,
  selectedStoreId: storeId,
}) => {
  return (dispatch, getState) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({
        type: COLLECTION_SWAP_START,
      })

      const failedFetch = (error) => {
        const reason = loggableError(error)
        debug(`Failed to swap collection ${collectionId}`, reason)
        dispatch({
          type: COLLECTION_SWAP_ERROR,
          payload: {
            errors: error?.response?.data?.errors?.base || [],
          },
        })
        reject(reason)
      }

      return axios
        .patch(`/api/v1/collections/${collectionId}/swap`, {
          with_collection_id: swapForCollectionId,
        })
        .then((response) => {
          const { id: newCollectionId } = response.data
          const state = getState()
          const collection = state.collections?.items[collectionId]
          const store = state.stores?.items[collection.storeId]
          const business = state.stores?.businesses[store.businessId]
          dispatch({
            type: COLLECTION_SWAP_SUCCESS,
            payload: {
              collectionId,
              swapForCollectionId,
              newCollectionId,
              storeId,
            },
            meta: {
              analytics: {
                eventName: COLLECTION_SWAP_SUCCESS,
                eventPayload: {
                  id: collectionId,
                  type: collection?.type,
                  businessId: store.businessId,
                  business: business?.name,
                  store: store?.name,
                  storeId,
                  nextCollection: collection?.nextCollection,
                  newCollectionId,
                },
              },
            },
          })
          resolve()
        })
        .catch(failedFetch)
    })
  }
}
