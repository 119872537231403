import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import closeSkipModal from '@redux/thunks/collections/closeSkipModal'
import closeSwapModal from '@redux/thunks/collections/closeSwapModal'
import closeUnassignModal from '@redux/thunks/collections/closeUnassignModal'
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections'
import { MY_COLLECTIONS_URL } from 'config/routes'
import {
  COLLECTION_ACTIONS,
  getActionModalKey,
  getCollectionActionDetails,
} from 'helpers/collections'
import Debug from 'helpers/debug'
import { useModal } from 'hooks/application'
import CollectionConfirmationInfo from 'components/Collections/CollectionConfirmationInfo'
import Button from 'components/common/Button'
import SuccessModal from 'components/common/Modal/SuccessModal'
import { Spacer } from 'components/layout/primitives'
import styles from './Success.module.scss'

const { SKIP, UNASSIGN, SWAP } = COLLECTION_ACTIONS
const debug = Debug('components:collections:success')

type Props = {
  mode: string
}

const Success = ({ mode }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const modalKey = getActionModalKey(mode)
  const { closeModal } = useModal(modalKey)

  const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
    return getCollectionActionDetails(t)
  }, [])

  const storeId = useAppSelector((state) => state?.collections[mode].selectedStoreId)
  const store = useAppSelector((state) => state?.stores.items[storeId])
  const storeName = store?.name
  const businessId = store?.businessId
  const businessName = useAppSelector((state) => state?.stores.businesses[businessId]?.name)

  const selectedDate = useAppSelector((state) => state?.collections[mode].selectedDate)
  const selectedCollectionId = useAppSelector(
    (state) => state?.collections[mode].selectedCollectionId,
  )
  const collection = useAppSelector((state) => state?.collections.items[selectedCollectionId])

  const swapForCollectionId = useAppSelector((state) => state.collections.swap?.swapForCollectionId)
  const swapForCollection = useAppSelector((state) => state.collections.items[swapForCollectionId])

  const newCollectionId = useAppSelector((state) => state.collections.swap?.newCollectionId)

  useEffect(() => {
    if (newCollectionId !== undefined) {
      dispatch(fetchStoreCollections({ storeId })).catch(debug)
    }
  }, [newCollectionId])

  const handleClose = () => {
    switch (mode) {
      case UNASSIGN:
        closeModal()
        dispatch(closeUnassignModal())
        window.location.assign(MY_COLLECTIONS_URL)
        break
      case SKIP:
        closeModal()
        dispatch(closeSkipModal())
        window.location.assign(MY_COLLECTIONS_URL)
        break
      case SWAP:
        closeModal()
        dispatch(closeSwapModal())
        window.location.assign(`${MY_COLLECTIONS_URL}/${newCollectionId}`)
        break
      default:
        break
    }
  }
  return (
    <SuccessModal
      className={styles.successModal}
      onClose={handleClose}
      modalKey={modalKey}
    >
      <h2>{t('collection-slots.claim.success_modal.success')}</h2>
      <h2 className={styles.headingText}>{COLLECTION_ACTIONS_DETAILS[mode].successHeader}</h2>
      <Spacer h="3rem" />
      {mode === SWAP && (
        <h3 className={styles.swapCollectionLabel}>
          {t('your-collections.page.show.swap-modal.success-modal.original-collection')}
        </h3>
      )}

      <CollectionConfirmationInfo
        businessName={businessName}
        storeName={storeName}
        storeAddress={store?.address}
        collectionTime={collection?.nextCollection}
        endDate={selectedDate}
        timezone={store?.timezone}
        mode={mode}
      />

      {mode === SWAP && swapForCollection && (
        <>
          <Spacer h="1.5rem" />
          <h3 className={styles.swapCollectionLabel}>
            {t('your-collections.page.show.swap-modal.success-modal.new-collection')}
          </h3>
          <CollectionConfirmationInfo
            businessName={businessName}
            storeName={storeName}
            storeAddress={store?.address}
            collectionTime={swapForCollection?.nextCollection}
            timezone={store?.timezone}
            mode={SWAP}
          />
        </>
      )}

      {mode === SKIP && (
        <>
          <p>{t('your-collections.page.show.skip-modal.success-modal.description_1')}</p>
        </>
      )}

      <Spacer h="3rem" />
      <Button
        className={styles.viewCollectionDetailsButton}
        onClick={handleClose}
      >
        {mode === UNASSIGN
          ? t('your-collections.page.show.modals.success-modal.view-collection-details')
          : t('your-collections.page.show.modals.success-modal.view-my-collections')}
      </Button>
    </SuccessModal>
  )
}

export default Success
