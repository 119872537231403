import { filtersDefaults } from 'components/AvailableCollections/AvailableCollectionsFilters/constants'
import {
  DistanceFilterKeys,
  TimeFilterKeys,
} from '../components/AvailableCollections/AvailableCollectionsFilters/constants'

interface Query {
  type?: string
  businessid?: string
  storeid?: string
  distance?: DistanceFilterKeys
  time?: TimeFilterKeys[]
  dateRange?: [string, string]
}

export const isFilterApplied = (query: Query) => {
  if (query.type && query.type !== filtersDefaults.type.default) {
    return true
  }

  if (query.businessid && query.businessid !== filtersDefaults.businessid.default) {
    return true
  }
  if (query.storeid && query.storeid !== filtersDefaults.storeid.default) {
    return true
  }

  if (query.distance && query.distance !== filtersDefaults.distance.default) {
    return true
  }

  if (query.time && query.time !== filtersDefaults.time.default) {
    return true
  }

  if (query.dateRange && query.dateRange[0] && query.dateRange[1]) {
    return true
  }
}
