import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { trackEvent } from 'services/Analytics'
import carrotWithStore from 'images/carrotWithStore.webp'
import {
  filters,
  filtersDefaults,
} from 'components/AvailableCollections/AvailableCollectionsFilters/constants'
import styles from 'components/AvailableCollections/AvailableCollectionsList/NoCollectionsNearMe/NoCollectionsNearMe.module.scss'
import Button from 'components/common/Button'
import Card from 'components/common/Cards/Card'

type NoCollectionsNearMeType = {
  collectionCount: number
}

const ResetFilters = ({ collectionCount }: NoCollectionsNearMeType) => {
  const { t } = useTranslation()
  const [_, setQuery] = useQueryParams()

  return (
    <Card className={styles.noCollectionsNearMe}>
      <Card.Heading>{t('available-collections.reset-filters.heading')}</Card.Heading>
      <Card.Image>{carrotWithStore}</Card.Image>
      <Card.TextSection className={styles.textSection}>
        <strong className={styles.subheading}>
          {collectionCount === 0
            ? t('available-collections.reset-filters.subheading')
            : t('available-collections.reset-filters.subheadingFewCollections')}
        </strong>

        <p>{t('available-collections.reset-filters.description1')}</p>
        <p>{t('available-collections.reset-filters.description2')}</p>
        <p>{t('available-collections.reset-filters.description3')}</p>
      </Card.TextSection>
      <Button
        className={styles.button}
        onClick={() => {
          setQuery({
            [filters.businessid]: filtersDefaults.businessid.default,
            [filters.storeid]: filtersDefaults.storeid.default,
            [filters.type]: filtersDefaults.type.default,
            [filters.distance]: filtersDefaults.distance.default,
            [filters.time]: filtersDefaults.time.default,
            [filters.dateRange]: filtersDefaults.dateRange.default,
          })
          trackEvent('FWH_FILTER_RESET', { origin: 'resetCard' })
        }}
        target="_blank"
      >
        {t('available-collections.reset-filters.buttonLabel')}
      </Button>
    </Card>
  )
}

export default ResetFilters
