import { useEffect, useMemo } from 'react'
import { lazy, Suspense } from 'react'
import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'
import { useQueryParams } from 'use-query-params'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectBusinessesForAvailableCollections } from '@redux/selectors/businesses'
import fetchMyInductions from '@redux/thunks/inductions/fetchMyInductions'
import { trackEvent } from 'services/Analytics'
import { COLLECTION_TYPES } from 'config/collections'
import { isFilterApplied } from 'helpers/filters'
import { useIsMobile } from 'hooks/application'
import AvailableCollectionsListCard from 'components/AvailableCollections/AvailableCollectionsListCard'
import LoadingSpinner from 'components/common/LoadingSpinner'
import { Grid, Spacer, Stack } from 'components/layout/primitives'
import { filtersDefaults } from '../AvailableCollectionsFilters/constants'
import {
  filterByCollectionType,
  sortCollections,
  filterByBusiness,
  filterByStore,
  filterByDistance,
  filterByTime,
  filterByDate,
} from '../AvailableCollectionsFilters/helpers'
import NoCollectionsNearMe from './NoCollectionsNearMe'
import ResetFilters from './ResetFilters'

const AvailableCollectionFilters = lazy(() => import('../AvailableCollectionsFilters'))
const SortBy = lazy(() => import('../AvailableCollectionsFilters/Filters/SortBy'))

const { ONGOING, ADHOC, TEMPORARY } = COLLECTION_TYPES

type AvailableCollectionsListType = {
  availableCollections: CollectionType[]
}

const AvailableCollectionsList = ({ availableCollections }: AvailableCollectionsListType) => {
  const dispatch = useAppDispatch()
  const businesses = useAppSelector(selectBusinessesForAvailableCollections)
  const isMobile = useIsMobile()
  const homeLocation = useAppSelector((state) => state.users.currentUser.location)
  const [query] = useQueryParams()

  const filteredAndSortedCollections = useMemo(() => {
    let collections = availableCollections

    if (query.type && query.type !== filtersDefaults.type.default) {
      collections = collections.filter((collection) =>
        filterByCollectionType(collection, query.type),
      )
    }

    if (query.businessid && query.businessid !== filtersDefaults.businessid.default) {
      collections = collections.filter((collection) =>
        filterByBusiness(collection, query.businessid),
      )
    }
    if (query.storeid && query.storeid !== filtersDefaults.storeid.default) {
      collections = collections.filter((collection) => filterByStore(collection, query.storeid))
    }

    if (query.distance && query.distance !== filtersDefaults.distance.default) {
      collections = collections.filter((collection) =>
        filterByDistance(collection, query.distance, homeLocation),
      )
    }

    if (query.time && query.time !== filtersDefaults.time.default) {
      collections = collections.filter((collection) => filterByTime(collection, query.time))
    }

    if (query.dateRange && query.dateRange[0] && query.dateRange[1]) {
      collections = collections.filter((collection) =>
        filterByDate(collection.nextCollection, query.dateRange?.[0], query.dateRange?.[1]),
      )
    }

    return sortCollections(collections, query.sort, homeLocation)
  }, [
    availableCollections,
    homeLocation,
    query.sort,
    query.type,
    query.businessid,
    query.storeid,
    query.distance,
    query.time,
    query.dateRange,
  ])

  useEffect(() => {
    if (businesses !== undefined && businesses.length !== 0) {
      const ongoingCollections = availableCollections.filter(
        (collection) => collection.type === ONGOING,
      ).length
      const adhocCollections = availableCollections.filter(
        (collection) => collection.type === ADHOC,
      ).length
      const temporaryCollections = availableCollections.filter(
        (collection) => collection.type === TEMPORARY,
      ).length

      trackEvent('LOAD_AVAILABLE_COLLECTIONS', {
        businesses: businesses.length,
        availableCollections: availableCollections.length,
        ongoingCollections,
        adhocCollections,
        temporaryCollections,
      })
    }
  }, [businesses])

  useEffect(() => {
    dispatch(fetchMyInductions())
  }, [])

  return (
    <>
      <Suspense fallback={<LoadingSpinner containerHeight="400px" />}>
        <AvailableCollectionFilters availableCollections={availableCollections} />
        <Spacer h="1.5rem" />
        <SortBy />
      </Suspense>

      <Spacer h="1.5rem" />
      <Stack gap="1.5rem">
        {filteredAndSortedCollections.length > 0 && (
          <Grid
            columns={isMobile === true ? 1 : 2}
            gap="1rem"
          >
            <AnimatePresence>
              {filteredAndSortedCollections.map((collection: any) => {
                const { business, store } = collection

                if (store?.location === undefined || business === undefined) {
                  return null
                }

                return (
                  <AvailableCollectionsListCard
                    key={collection.collectionId}
                    collectionId={Number(collection.collectionId)}
                    storeName={store?.name}
                    businessName={business?.name}
                    storeAddress={store?.address}
                    type={collection.type}
                    nextCollection={collection.nextCollection}
                    storeLocation={store?.location}
                    collectionNotes={collection?.notes?.trim()}
                    storeTimezone={store?.timezone}
                    donationConfirmedAt={collection.donationConfirmedAt}
                  />
                )
              })}
            </AnimatePresence>
          </Grid>
        )}

        {filteredAndSortedCollections.length < 4 && (
          <Grid
            className={clsx(filteredAndSortedCollections.length !== 0 && 'no-collections-border')}
            columns={isMobile === true ? 1 : 2}
            gap="1rem"
          >
            {availableCollections.length <= 3 && !isFilterApplied(query) ? (
              <NoCollectionsNearMe collectionCount={filteredAndSortedCollections.length} />
            ) : (
              <ResetFilters collectionCount={filteredAndSortedCollections.length} />
            )}
          </Grid>
        )}
      </Stack>
    </>
  )
}

export default AvailableCollectionsList
