import {
  COLLECTION_SKIP_SET_ACTIVE_STEP,
  COLLECTION_SWAP_SET_ACTIVE_STEP,
  COLLECTION_UNASSIGN_SET_ACTIVE_STEP,
} from '@redux/actionTypes'
import { COLLECTION_ACTIONS } from 'helpers/collections'
import Debug from 'helpers/debug'

const { UNASSIGN, SKIP, SWAP } = COLLECTION_ACTIONS

const setActiveStep = ({ action, step }) => {
  const debug = Debug(`javascript:redux:thunks:collections:set-active-step-on-${action}-modal`)
  let actionToSend

  switch (action) {
    case UNASSIGN:
      actionToSend = COLLECTION_UNASSIGN_SET_ACTIVE_STEP
      break
    case SKIP:
      actionToSend = COLLECTION_SKIP_SET_ACTIVE_STEP
      break
    case SWAP:
      actionToSend = COLLECTION_SWAP_SET_ACTIVE_STEP
      break
    default:
      actionToSend = undefined
      debug('Invalid collection action')
      break
  }

  return (dispatch) => {
    dispatch({
      type: actionToSend,
      payload: { step },
    })
  }
}

export default setActiveStep
