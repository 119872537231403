import { getDistance } from 'geolib'
import { COLLECTION_TYPES } from 'config/collections'
import {
  sortByFilterKeys,
  collectionTypeFilterKeys,
  businessFilterKeys,
  storeFilterKeys,
  distanceFilterKeys,
  DistanceFilterKeys,
  TimeFilterKeys,
  timeFilterKeys,
} from 'components/AvailableCollections/AvailableCollectionsFilters/constants'

const { ONGOING, ADHOC, TEMPORARY } = COLLECTION_TYPES

export const getBusinessFilterOptions = (businesses) => {
  const availableBusinesses = businesses.map((business) => ({
    value: business?.id,
    label: business?.name,
  }))
  return availableBusinesses.sort((a, b) => (a.label > b.label ? 1 : -1))
}

export const getStoreFilterOptions = (stores: StoreType[], businessId: string) => {
  const associatedStores = []
  for (const [key, store] of Object.entries(stores)) {
    if (store.businessId === Number(businessId)) {
      associatedStores.push({ value: key, label: store.name })
    }
  }
  return associatedStores.sort((a, b) => a.label.localeCompare(b.label))
}

/**
 * @param {string} date ISO Date to use for calculation
 */
export const getTimeOfDay = (dateTime: string) => {
  const date = new Date(dateTime)
  const hours = date.getHours()

  if (hours >= 7 && hours < 12) {
    return timeFilterKeys.morning
  } else if (hours >= 12 && hours < 17) {
    return timeFilterKeys.afternoon
  } else if (hours >= 17 && hours < 20) {
    return timeFilterKeys.earlyEvening
  } else if (hours >= 20 && hours <= 23) {
    return timeFilterKeys.evening
  }
}

export const filterByCollectionType = (collection, type) => {
  switch (type) {
    case collectionTypeFilterKeys.ONGOING:
      return collection.type === ONGOING
    case collectionTypeFilterKeys.ADHOC:
      return collection.type === ADHOC
    case collectionTypeFilterKeys.TEMPORARY:
      return collection.type === TEMPORARY
    default:
      return true
  }
}

export const filterByBusiness = (collection: CollectionType, businessId: string) => {
  if (businessId === businessFilterKeys.all) {
    return true
  }
  return collection.business?.id === Number(businessId)
}

export const filterByStore = (collection: CollectionType, storeId: string) => {
  if (storeId === storeFilterKeys.all) {
    return true
  }
  return collection.storeId === Number(storeId)
}

export const filterByDate = (nextCollection: string, startDate: Date, endDate: Date): boolean => {
  if (!startDate || !endDate) {
    return true
  }
  const collectionDate = new Date(nextCollection)
  return collectionDate >= new Date(startDate) && collectionDate <= new Date(endDate)
}

export const filterByDistance = (
  collection: CollectionType,
  distanceFilterPreference: DistanceFilterKeys,
  homeLocation: {
    latitude: number
    longitude: number
  },
) => {
  if (!collection.store?.location || distanceFilterPreference === distanceFilterKeys.all) {
    return true
  }

  const distance = getDistance(homeLocation, collection.store.location)
  const distanceInKm = distance / 1000
  return distanceInKm <= Number(distanceFilterPreference)
}

export const filterByTime = (collection: CollectionType, timeFilterPreference: TimeFilterKeys[]) =>
  timeFilterPreference.includes(getTimeOfDay(collection.nextCollection))

export const sortCollections = (
  collections: CollectionType[],
  sort: string,
  homeLocation: { latitude: number; longitude: number },
) => {
  return collections.sort((a, b) => {
    if (!a.store?.location || !b.store?.location) {
      return 0
    }

    const collectionATime = new Date(a.nextCollection).getTime()
    const collectionBTime = new Date(b.nextCollection).getTime()
    const distanceAccuracyInMeters = 100
    const collectionADistance = getDistance(
      homeLocation,
      a.store.location,
      distanceAccuracyInMeters,
    )
    const collectionBDistance = getDistance(
      homeLocation,
      b.store.location,
      distanceAccuracyInMeters,
    )

    if (sort === sortByFilterKeys.soonest) {
      if (collectionATime === collectionBTime) {
        return collectionADistance - collectionBDistance
      }
      return collectionATime - collectionBTime
    } else if (sort === sortByFilterKeys.closest) {
      if (collectionADistance === collectionBDistance) {
        return collectionATime - collectionBTime
      }
      return collectionADistance - collectionBDistance
    }

    return 0
  })
}
