import { useTranslation } from 'react-i18next'
import { MdEvent } from 'react-icons/md'
import clsx from 'clsx'
import { COLLECTION_TYPES } from 'config/collections'
import {
  calculateRelativeTimeFromNow,
  formatDateToDayAndTime24,
  formatDateToDayMonthAndTime,
} from 'helpers/time'
import { useCollection } from 'hooks/collections'
import styles from './CollectionDates.module.scss'

type Props = {
  collectionId: number
  nextWindowDate?: string
}

const CollectionDates = ({ collectionId, nextWindowDate }: Props) => {
  const { t } = useTranslation()
  const { data: collection } = useCollection({ collectionId })
  const {
    type,
    nextCollection,
    endDate: endCollectionDate,
    store: { timezone: storeTimezone },
  } = collection

  const nextCollectionDate = nextWindowDate || nextCollection
  const shouldDisplayNextCollection =
    nextCollectionDate &&
    (!endCollectionDate || Date.parse(nextCollectionDate) <= Date.parse(endCollectionDate))

  const displayCollectionFrequency = () => {
    if (type === COLLECTION_TYPES.ADHOC || type === COLLECTION_TYPES.TEMPORARY) {
      return t(`collection-slots.claim.types.${type}`)
    }
    return t('collection-slots.frequency.weekly')
  }

  return (
    <div className={clsx(styles.collectionDates, { [styles.adhocCollection]: type === 'adhoc' })}>
      <div className={clsx(styles.collectionDate, styles.nextCollection)}>
        <label>{t('your-collections.page.general.collection.next_time')}</label>
        {shouldDisplayNextCollection && (
          <strong className={styles.collectionDateRelative}>
            {calculateRelativeTimeFromNow(nextCollectionDate)}
          </strong>
        )}
        <strong className={styles.nextCollection}>
          ({formatDateToDayMonthAndTime(nextCollectionDate, storeTimezone)})
        </strong>
        <MdEvent className={styles.calendarIcon} />
      </div>

      <div className={styles.collectionDate}>
        <label>{t('your-collections.page.general.collection.current_time')}</label>
        <strong>
          {shouldDisplayNextCollection
            ? formatDateToDayAndTime24(nextCollectionDate, storeTimezone)
            : t('your-collections.page.general.collection.no_next_collection')}
        </strong>
        <strong>
          <span>(</span>
          {displayCollectionFrequency()}
          <span>)</span>
        </strong>
      </div>
      {type !== COLLECTION_TYPES.ADHOC && (
        <div className={styles.collectionDate}>
          <label>{t('your-collections.page.general.collection.last_time')}</label>
          {endCollectionDate && (
            <strong className={styles.collectionDateRelative}>
              {calculateRelativeTimeFromNow(endCollectionDate)}
            </strong>
          )}
          <strong>
            {endCollectionDate
              ? `(${formatDateToDayMonthAndTime(endCollectionDate)})`
              : t('your-collections.page.general.collection.no_end_date')}
          </strong>
        </div>
      )}
    </div>
  )
}

export default CollectionDates
