import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import FieldError from 'components/common/FieldError'
import styles from './TextField.module.scss'

type Props = {
  name: string
  rules?: Record<string, unknown>
  defaultValue?: string
  labelText: string
  customErrorMessage?: any
  type?: string
  autoComplete?: string
  onChange?: () => void
}

const TextField = ({
  name,
  rules,
  defaultValue,
  labelText,
  customErrorMessage,
  type = 'text',
  autoComplete,
  onChange,
}: Props) => {
  const { control } = useFormContext()
  const isRequired = rules?.required === true

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange: onChangeNative, name, ref, onBlur, value }, fieldState }) => {
        const handleChange = (event) => {
          if (onChange !== undefined) {
            onChange()
          }
          onChangeNative(event.target.value)
        }

        return (
          <>
            <label
              className={styles.textField}
              htmlFor={name}
            >
              <input
                ref={ref}
                id={name}
                onChange={handleChange}
                onBlur={onBlur}
                type={type}
                autoComplete={autoComplete}
                value={value ?? defaultValue ?? ''}
              />
              <span className={clsx(isRequired && styles.isRequired)}>{labelText}</span>
            </label>
            {fieldState.error && (
              <FieldError
                error={{
                  ...fieldState.error,
                  message: customErrorMessage,
                }}
              />
            )}
          </>
        )
      }}
    />
  )
}

export default TextField
